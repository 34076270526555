import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "./statementUploader.css";
import { Modal, ModalBody, Button } from "reactstrap";
import {
  ALL_LIVEFEEDBACK_DATA,
  IMAGE_UNPROCESSABLE,
  LIVEFEEDBACK_INVALID_DOC,
  REDIRECT_TO_UPLOAD,
  SOMETHING_WENT_WRONG,
} from "../../actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import TechnicalErrorIconSvg from "../../helpers/technicalError";
import Footer from "../view/Footer";


const ImageToPdfFailed = ({ allFiles }) => {
  const dispatch = useDispatch();

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  const imageUnprocessable = useSelector(
    (state) => state.livefeedback?.imageUnprocessable
  );


  const redirectToUpload = () => {

    dispatch({ type: REDIRECT_TO_UPLOAD });
    dispatch({ type: 
      IMAGE_UNPROCESSABLE, payload: {reason: "", error: false } });
  };
  const mainMessage = imageUnprocessable?.reason?.split(":")[0] + ":";
  const reasonText = imageUnprocessable?.reason?.split(":")[1];
  const lines = reasonText?.split(/\d+\./).filter(line => line.trim());

  return (
    <div
      className="card-body popup  text-centered p-4 rounded-2"

    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          alignContent: "stretch",
          flexWrap: "nowrap",
          alignItems: "center",
          // padding:"20px"
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <div>
            <TechnicalErrorIconSvg />
          </div>
          <div
            className="upload-title-text"
          >
            Unproccessable Image!

          </div>
          {/* <div
          className="upload-content-text"
        >
         Unproccessable Image
        </div> */}
          <div
            className="upload-content-text" style={{ textAlign: "left" }}
          >
            {mainMessage}
            {lines?.length > 0 && (
              <ol>
                {lines.map((line, index) => (
                  <li key={index}>{line.trim()}</li>
                ))}
              </ol>
            )}
          </div>

          <Button
            className="upload-button"
            // id="browse"
            onClick={
              redirectToUpload}
          >
            Try Again
          </Button>
        </div>
      </div>


      <Footer />
    </div>
    //   {/* </ModalBody>
    // </Modal> */}
  );
};

export default ImageToPdfFailed;
