import { env as environment } from "../environments";
import { CORRUPT_FILE, LIVEFEEDBACK_DATA, LIVEFEEDBACK_FAILURE, LIVEFEEDBACK_INVALID_DOC, LIVEFEEDBACK_START, LIVEFEEDBACK_SUCCESS, PASSWORD_PROTECTED, SHOW_PROGRESS_LOADER, SOMETHING_WENT_WRONG } from "./actionTypes";
import { updateSession } from "../helpers/updateSessionHandler";
import { fetchApiWithFormData } from "../helpers/fetchApiWithFormData";

export const liveFeedback =  (file,category,sessionId,largeImage,imageToPdfConverted, subcategorySelected,documentSelected) => {

  return async (dispatch) => {
    try {
    if (category === "bank" || category === "address") {
      dispatch({ type: LIVEFEEDBACK_START });

      const Header = {
        "Content-Type": "application/json",
      };

      const liveFeedbackForm = new FormData();
      let datetime = new Date().getTime().toString();

      const key = sessionId + "-" + datetime + ".pdf";
      liveFeedbackForm.append("file", file, key);
      liveFeedbackForm.append("category", category);
      liveFeedbackForm.append("sessionid", sessionId);
      liveFeedbackForm.append("largeImage", largeImage);
      liveFeedbackForm.append("imageToPdfConverted", imageToPdfConverted);

      if(subcategorySelected && documentSelected){
        liveFeedbackForm.append("subCategory", JSON.stringify(subcategorySelected));
        liveFeedbackForm.append("documentCheck", JSON.stringify(documentSelected));
      }

      const response =  await fetchApiWithFormData(environment.getProcessedDataFromChatGpt,liveFeedbackForm,200,dispatch);
      var send_livefeedback = {
        sessionid: sessionId,
        data: { livefeedback: response.data }
      };
      dispatch(updateSession(send_livefeedback,sessionId));
      dispatch({ type: LIVEFEEDBACK_DATA, payload: response?.message?.response });
      dispatch({ type: SHOW_PROGRESS_LOADER, payload: false });
    
    if (
      response?.status === 200 &&
      response?.message?.response?.document_type === "Invalid"
    ) {
      // To call the wrong document popup, call the below action with the given payload
      dispatch({ type: LIVEFEEDBACK_INVALID_DOC, payload:true });
    }
    else if(
      response?.message?.response?.error == true &&
      response?.message?.response?.toLowerCase().includes("password")
    ) {
      dispatch({type:PASSWORD_PROTECTED, payload:true})
    }
    else if(
      response?.message?.response?.error == true &&
      response?.message?.response?.error?.toLowerCase().includes("Failed to parse PDF document".toLowerCase()) || 
      response?.message?.response?.error?.toLowerCase().includes("Data is not extracted from pdf".toLowerCase())
    ) {
      dispatch({type:CORRUPT_FILE, payload:true})
    }
    else if(response?.status !== 200 ||  response?.message?.response?.error){
      dispatch({ type: LIVEFEEDBACK_FAILURE, payload: true });
      dispatch({ type: SOMETHING_WENT_WRONG, payload: true });
      dispatch({ type: SHOW_PROGRESS_LOADER, payload: false });
    }
    else{
      const chatGptResponse = response?.message?.response;
      dispatch({ type: LIVEFEEDBACK_SUCCESS, payload: chatGptResponse });
    }
    }
}catch(error){
  // console.log("err",error)

// if(response.status === 413){
  dispatch({ type: LIVEFEEDBACK_FAILURE, payload: true });
  dispatch({ type: SOMETHING_WENT_WRONG, payload: true });
  dispatch({ type: SHOW_PROGRESS_LOADER, payload: false });
// }
}
  }
}