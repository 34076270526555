import React from "react";
import { useSelector } from "react-redux";
import Footer from "../Footer";
import "./index.css";


// Add more detailed upload SVG component
const UploadIcon = ({ height, color = "black" }) => {
    const lastLinkClickedData = useSelector(
        (state) => state.lastLinkClicked?.dataSuccess
      );
  return (
    <svg 
      width={height} 
      height={height} 
      viewBox="0 0 24 24" 
      fill="none" 
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Cloud background */}
      <path 
        d="M19.35 10.04C18.67 6.59 15.64 4 12 4C9.11 4 6.6 5.64 5.35 8.04C2.34 8.36 0 10.91 0 14C0 17.31 2.69 20 6 20H19C21.76 20 24 17.76 24 15C24 12.36 21.95 10.22 19.35 10.04Z" 
        fill={lastLinkClickedData?.button?.setcolor || "black"} 
      />
      {/* White arrow */}
      <path 
        d="M14 13V17H10V13H7L12 8L17 13H14Z" 
        fill="white" 
      />
    </svg>
  );
};

export default function Upload({
    handleBrowseClick,
    handleDragOver,
    handleDrop,
    handleFileSelect,
    fileInputRef,
}) {
    const lastLinkClickedData = useSelector(
        (state) => state.lastLinkClicked?.dataSuccess
    );

    const getCategory = (category) => {
        if (category === "address") {
            return "utility bill";
        } else {
            return category + " statement";
        }
    };

    return (
        <div
            className="card-body text-centered p-4 rounded-2 popup"
        >
            <div className="upload-boundary">
            <div
                className="uploader-col"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <div>
                    <UploadIcon height={120} />
                </div>
                <div>
                    {lastLinkClickedData?.button?.multidownload
                        ? "Drop your " +
                        lastLinkClickedData?.button?.dataRangeInMonths +
                        " months " +
                        (lastLinkClickedData
                            ? getCategory(lastLinkClickedData?.category)
                            : "satement")
                        :
                        <div className="upload-text-container">
                            <div className="upload-title-text-container">
                            <div className="upload-title-text">Drag & drop or</div>
                            <div onClick={handleBrowseClick} className="upload-title-upload upload-title-text"> click to upload </div>
                            <div className="upload-title-text">{(lastLinkClickedData
                                ? getCategory(lastLinkClickedData?.category)
                                : "statement")}</div>
                                </div>
                        </div>
                    }
                </div>

                <input
                    ref={fileInputRef}
                    className="d-none"
                    type="file"
                    onChange={handleFileSelect}
                    multiple={lastLinkClickedData?.button?.multidownload}
                />
            </div>

            <Footer /> 
            </div>
        </div>
    );
}
