import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import indexRoutes from "./indexRoutes";
import PageNotFound from "../Components/view/PageNotFound";
import Wrapper from "../pages/Wrapper";
import { env as environment } from "../environments";

const Router = (props) => {
  const data = props; // Keep the entire props object

  console.log("Data in Router.js:", data);

  return (
    <BrowserRouter>
      <Routes>
        {/* Conditional rendering based on the presence of any data */}
        {(environment["env"] === "CDN") ? ( // Check if any data is present
          <Route
            path="*"
            element={<Wrapper data={data} />} // Pass all data to Wrapper
          />
        ) : (
          <>
            {/* Default route rendering when no data is available */}
            {indexRoutes.map((route, key) => (
              <Route
                path={route.path}
                key={key}
                element={
                  <route.page />
                }
              />
            ))}
            {/* Page not found route */}
            <Route path="*" element={<PageNotFound />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default Router;