import axios from "axios";
import { env as environment } from "../environments";


export const s3fileHandler = async (file,sessionId,type) => {

  try {
    const formData = new FormData();
    if(type === "image"){
      formData.append("filename", sessionId + "-" + "upload" + "-" +"image");
      formData.append("file", file, sessionId + "-" + "upload" + "-" + "image");
    }else{
      formData.append("filename", sessionId + "-" + "upload" + ".pdf");
      formData.append("file", file, sessionId + "-" + "upload" + ".pdf");
    }
  
    await axios.post(environment.s3Upload, formData);    
   }catch (err) {
      console.error(`Error in s3File upload`, err.message);
      throw err;
    }
}