import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VerificationLinkPoc } from "../../actions/VerificationLinkPoc";
import { unsupportedMedia, uploadFileAction } from "../../actions/Uploadaction";
import { liveFeedback } from "../../actions/liveFeddback";
import { updateSession } from "../../helpers/updateSessionHandler";
import Upload from "../../Components/view/Upload";
import PleaseWait from "../../Components/templates/waiting.template";
import UploadStatus from "../../Components/uploadStatus";
import ThankYouModal from "../../Components/templates/thankyou.template";
import UrlNotFound from "../../Components/newTemplates/urlNotFound";
import { ProgressWidget } from "../../Components/newTemplates/ProgressWidget";
import UnsupportedMedia from "../../Components/templates/unsupported_file.template";
import {
  BUTTONID_IS_NULL,
  FILE_ALREADY_EXISTS,
  LIMIT_EXCEED,
  ONESHOTUPLOAD_FAILURE,
  ONESHOTUPLOAD_START,
  ONESHOTUPLOAD_SUCCESS,
  URL_NOT_FOUND,
  SOMETHING_WENT_WRONG,
  IS_VERIFYING,
  SHOW_PROGRESS_LOADER,
  IMAGE_UPLOAD,
  IMAGE_UNPROCESSABLE,
} from "../../actions/actionTypes";
import { sendLogs } from "../../helpers/sendLogs";
import axios from "axios";
import { env as environment } from "../../environments";
import {
  PdfToBase64,
  b64toBlob,
  blobToFile,
} from "../../helpers/pdfFileChangerHandler";
//import DocumentNotOriginal from "../../Components/newTemplates/DocumentNotOriginal";
import ButtonIdNotFound from "../../Components/newTemplates/ButtonIdNotFound";
import SomethingWentWrong from "../../Components/newTemplates/somethingWentWrong";
import PasswordProtected from "../../Components/newTemplates/passwordProtected";
import CorruptFile from "../../Components/newTemplates/corruptFile";
import LimitExceed from "../../Components/newTemplates/limitExceed";
import LiveFeedbackInvalidPopup from "../../Components/view/LiveFeedbackInvalidPopup";
import { convertPngToJpeg } from "../../helpers";
import { s3fileHandler } from "../../actions/uploads3_Action";
import ImageToPdfFailed from "../../Components/newTemplates/ImageToPdfFailed";

export default function Wrapper() {
  const [params, setParams] = useState({});
  const [allFiles, setAllFiles] = useState([]);
  const [allProcessedFiles, setAllProcessedFiles] = useState([]);
  const progressRef = useRef();

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  // Check if document is invalid
  const livefeedbackInvalidDoc = useSelector(
    (state) => state.livefeedback?.livefeedbackInvalidDoc
  );

  const somethingWentWrong = useSelector(
    (state) => state.upload?.somethingWentWrong
  );

  const lastLinkClickedData = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess
  );

  const isVerifying = useSelector((state) => state?.upload?.isVerifying);
  const urlNotFound = useSelector((state) => state?.oneShotUpload?.error);

  const processingLivefeedback = useSelector(
    (state) => state?.livefeedback?.processingLivefeedback
  );

  const processingOneShotUpload = useSelector(
    (state) => state?.oneShotUpload?.oneShotStart
  );

  const oneShotUploadSuccessResponse = useSelector(
    (state) => state?.oneShotUpload?.oneShotSuccess
  );

  const passwordProtected = useSelector(
    (state) => state?.upload?.passwordProtected
  );

  const corruptFile = useSelector((state) => state?.upload?.corruptFile);

  const limitExceed = useSelector((state) => state?.upload?.limitExceed);

  const documentIsVerified = useSelector(
    (state) => state?.upload?.documentIsVerified
  );

  const documentIsWrong = useSelector(
    (state) => state?.upload?.documentIsWrong
  );

  const isUnsupportedMedia = useSelector(
    (state) => state?.upload?.unsupportedMedia
  );

  const fileAlreadyExists = useSelector(
    (state) => state?.upload?.fileAlreadyExists
  );

  const documentIsNotOriginal = useSelector(
    (state) => state?.upload?.documentIsNotOriginal
  );

  const buttonIdIsNull = useSelector((state) => state?.upload?.buttonIdIsNull);

  const errorInVerificationPoc = useSelector(
    (state) => state?.verificationLinkPOC?.error
  );

  const subcategorySelected = useSelector(
    (state) => state.livefeedback?.subcategorySelected
  );

  const documentSelected = useSelector(
    (state) => state.livefeedback?.documentSelected
  );

  const showProgressLoader = useSelector(
    (state) => state.upload?.showProgressLoader
  );

  const progressLivefeedbackLoader = useSelector(
    (state) => state.livefeedback?.progressLivefeedbackLoader
  );

  const imageUnprocessable = useSelector(
    (state) => state.livefeedback?.imageUnprocessable
  );

  // const liveFeedbackData = useSelector(
  //   (state) => state.livefeedback?.livefeedbackData
  // );

  const dispatch = useDispatch();

  useEffect(() => {

    if (progressRef.current) {
      progressRef.current.updateProgressAndMessage(progressLivefeedbackLoader);
    }
  }, [progressLivefeedbackLoader]);

  useEffect(() => {
    if (lastLinkClickedData?.button?.setcolor) {
      document.documentElement.style.setProperty('--dynamic-color', lastLinkClickedData.button.setcolor);
    }
  }, [lastLinkClickedData]);

  const getPdfFileName = (fileName) => {
    const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');
    const extension = fileName.split('.').pop();
    return `${fileNameWithoutExtension}.pdf`;
  }
  // Function to upload file from system.
  const handleFileSelect = async (event) => {
    try {
      event.preventDefault();
      let file = event.target.files[0] || event.dataTransfer.files[0];
      const fileNameNew = await getPdfFileName(file.name)

      const fileNameObj = {
        data: {
          originalFileName: fileNameNew,
        },
        sessionid: lastLinkClickedData.sessionid,
      }

     dispatch(updateSession({...fileNameObj}))

      var notSupportedDocument = {
        data: {
          indicator: {
            progress_no: "6",
            progress_name: "Uploaded document not supported",
            Detection: "upload",
          },
        },
        sessionid: lastLinkClickedData.sessionid,
      };
      if (file && (file.type === "application/pdf") || (lastLinkClickedData?.button?.imageUpload !== false && lastLinkClickedData?.button?.imageUpload !== "false") && file && file.type === "application/pdf" || file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" || file.type === "image/heic" || (file.name.toLowerCase().endsWith('.heic'))) {
        const fileSizeInBytes = file.size; // Get file size in bytes
        const fileSizeInKB = fileSizeInBytes / 1024; // Convert to KB
        const fileSizeInMB = fileSizeInKB / 1024; // Convert to MB

        let imageToPdfConverted = false;

        if (file.type !== "application/pdf") {
          dispatch({ type: IMAGE_UPLOAD, payload: true });
          if (fileSizeInMB > 5) {
            return dispatch({ type: LIMIT_EXCEED, payload: true });
          }
          s3fileHandler(file,lastLinkClickedData.sessionid,"image");
        } else {
          dispatch({ type: IMAGE_UPLOAD, payload: false });
          if (fileSizeInMB > 50) {
            return dispatch({ type: LIMIT_EXCEED, payload: true });
          }
        }

        if (file.name.toLowerCase().endsWith('.heic') && !file.type) {
          // Create a new File object with the correct MIME type
          const modifiedFile = new File([file], file.name, {
            type: 'image/heic',
            lastModified: file.lastModified,
          });
          file = modifiedFile;
        }
        // if file already exists already, show already exists popup and for the same dispatch the below action.

        if (allFiles.find((item) => item.name === file.name)) {
          return dispatch({ type: FILE_ALREADY_EXISTS, payload: true });
        }
        // Handle JPEG, JPG, HEIC file formats
        const imageMimeTypes = ["image/jpeg", "image/jpg", "image/heic", "image/png"];
        if (imageMimeTypes.includes(file?.type)) {

          // dispatch({ type: IS_VERIFYING, payload: true });
          dispatch({ type: SHOW_PROGRESS_LOADER, payload: true });

          const formData = new FormData();

          if (file?.type === "image/png") {
            // console.log("if image type png");
            const jpegBlob = await convertPngToJpeg(file);
            const convertedJPEGData = await new Response(jpegBlob).blob();

            const imageBlob = new Blob([convertedJPEGData], { type: 'image/jpeg' });
            formData.append('file', imageBlob, {
              filename: file.name, // Use the original file name
              contentType: "image/jpg",
            });
          } else {
            // Append the file with proper metadata
            formData.append("file", file, {
              filename: file.name, // Use the original file name
              contentType: file.type,
            });
          }
          formData.append("sessionid", lastLinkClickedData.sessionid);
          const imageToPdfApiUrl = environment.imageToPdfApiUrl;
          const response = await axios.post(imageToPdfApiUrl, formData, {
            headers: {
              Origin: environment.origin
            },
          });
              imageToPdfConverted = true;
          let blob = new Blob([response?.data], { type: 'application/pdf' });
          file = blob;
        }
        dispatch(
          uploadFileAction(
            file,
            params,
            lastLinkClickedData.category,
            lastLinkClickedData.sessionid
          )
        );
        setAllFiles([...allFiles, ...event.target.files]);
        setAllProcessedFiles([...allProcessedFiles, file]);
        dispatch(
          liveFeedback(
            file,
            lastLinkClickedData.category,
            lastLinkClickedData.sessionid,
            fileSizeInMB > 3 ? true : false,
            imageToPdfConverted,
            subcategorySelected,
            documentSelected,
          )
        );


      } else {
        dispatch(unsupportedMedia(true));
        if (params.buttonid) {
          dispatch(
            updateSession(notSupportedDocument, lastLinkClickedData.sessionid)
          );
        }
      }
      event.target.value = null;
    }
    catch (error) {
      if(error.response.data.documentUnprocessable == true) {
        dispatch({ type: IMAGE_UNPROCESSABLE, payload: {reason: error.response.data.reason, error: true } })
        dispatch({ type: SHOW_PROGRESS_LOADER, payload: false });
      }else{
      dispatch({ type: SOMETHING_WENT_WRONG, payload: true })
      }
    }
  }
  // Function to handle browse click event.
  const handleBrowseClick = () => {
    // Trigger click event on file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const fileInputRef = useRef(null);

  // Function to drag & drop file from system.
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Function to drag & drop file from system.
  const handleDrop = async (event) => {
    try {
      event.preventDefault();
      let file = event.dataTransfer.files[0];
      let fileDropped = event.dataTransfer.files[0];

      const fileNameNew = await getPdfFileName(file.name)

      const fileNameObj = {
        data: {
          originalFileName: fileNameNew,
        },
        sessionid: lastLinkClickedData.sessionid,
      }

     dispatch(updateSession({...fileNameObj}))

      var notSupportedDocument = {
        data: {
          indicator: {
            progress_no: "6",
            progress_name: "Uploaded document not supported",
            Detection: "upload",
          },
        },
        sessionid: lastLinkClickedData.sessionid,
      };
      if (file && (file.type === "application/pdf") || (lastLinkClickedData?.button?.imageUpload !== false && lastLinkClickedData?.button?.imageUpload !== "false") && file && file.type === "application/pdf" || file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg" || file.type === "image/heic" || (file.name.toLowerCase().endsWith('.heic'))) {
        const fileSizeInBytes = file.size; // Get file size in bytes
        const fileSizeInKB = fileSizeInBytes / 1024; // Convert to KB
        const fileSizeInMB = fileSizeInKB / 1024; // Convert to MB

        let imageToPdfConverted = false;

        if (file.type !== "application/pdf") {
          dispatch({ type: IMAGE_UPLOAD, payload: true });
          if (fileSizeInMB > 5) {
            return dispatch({ type: LIMIT_EXCEED, payload: true });
          }
          s3fileHandler(file,lastLinkClickedData.sessionid,"image");
        } else {
          dispatch({ type: IMAGE_UPLOAD, payload: false });
          if (fileSizeInMB > 50) {
            return dispatch({ type: LIMIT_EXCEED, payload: true });
          }
        }

        if (file.name.toLowerCase().endsWith('.heic') && !file.type) {
          // Create a new File object with the correct MIME type
          const modifiedFile = new File([file], file.name, {
            type: 'image/heic',
            lastModified: file.lastModified,
          });
          file = modifiedFile;
        }
        // if file already exists already, show already exists popup and for the same dispatch the below action.
        else {
          if (allFiles.find((item) => item.name === file.name)) {
            dispatch({ type: FILE_ALREADY_EXISTS, payload: true });
          }
          // if file is new i.e. doesn't exists already, do further process.
          else {

            // Handle JPEG, JPG, HEIC file formats
            const imageMimeTypes = ["image/jpeg", "image/jpg", "image/heic", "image/png"];
            if (imageMimeTypes.includes(file?.type)) {

              // dispatch({ type: IS_VERIFYING, payload: true });
              dispatch({ type: SHOW_PROGRESS_LOADER, payload: true });

              const formData = new FormData();

              if (file?.type === "image/png") {
                // console.log("if image type png");
                const jpegBlob = await convertPngToJpeg(file);
                const convertedJPEGData = await new Response(jpegBlob).blob();

                const imageBlob = new Blob([convertedJPEGData], { type: 'image/jpeg' });
                formData.append('file', imageBlob, {
                  filename: file.name, // Use the original file name
                  contentType: "image/jpg",
                });
              } else {
                // Append the file with proper metadata
                formData.append("file", file, {
                  filename: file.name, // Use the original file name
                  contentType: file.type,
                });
              }
              formData.append("sessionid", lastLinkClickedData.sessionid);
              const imageToPdfApiUrl = environment.imageToPdfApiUrl;
              const response = await axios.post(imageToPdfApiUrl, formData, {
                headers: {
                  Origin: environment.origin
                }
                });
              imageToPdfConverted = true;
              let blob = new Blob([response?.data], { type: 'application/pdf' });
              file = blob;
            }
            dispatch(
              uploadFileAction(
                file,
                params,
                lastLinkClickedData.category,
                lastLinkClickedData.sessionid
              )
            );
            setAllFiles([...allFiles, fileDropped]);
            setAllProcessedFiles([...allProcessedFiles, file]);
            dispatch(
              liveFeedback(
                file,
                lastLinkClickedData.category,
                lastLinkClickedData.sessionid,
                fileSizeInMB > 3 ? true : false,
                imageToPdfConverted,
                subcategorySelected,
                documentSelected,
              )
            );
          }
        }
      } else {
        dispatch(unsupportedMedia(true));
        if (params.buttonid) {
          dispatch(
            updateSession(notSupportedDocument, lastLinkClickedData.sessionid)
          );
        }
      }
      event.target.value = null;
    }
    catch (error) {
      if(error.response.data.documentUnprocessable == true) {
        dispatch({ type: IMAGE_UNPROCESSABLE, payload: {reason: error.response.data.reason, error: true } })
        dispatch({ type: SHOW_PROGRESS_LOADER, payload: false });
      } else {
        dispatch({ type: SOMETHING_WENT_WRONG, payload: true })
      }
    };
  };

  useEffect(() => {
    const newParams = {};
    if (environment["env"] === "CDN") {
      newParams["buttonid"] = environment["buttonId"];
      newParams["trackId"] = environment["trackId"];
      setParams(newParams);
      document.documentElement.style.setProperty('--env-cdn-app-header', 'auto');
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      for (const [key, value] of queryParams) {
        newParams[key] = value;
      }
      if (Object.keys(newParams).length > 0) {
        setParams(newParams);
      }
    }
  }, []);

  useEffect(() => {
    if (params && params.hasOwnProperty("buttonid") && params.buttonid) {
      dispatch(VerificationLinkPoc(params));
    } else if (params.hasOwnProperty("buttonid") && !params.buttonid) {
      dispatch({ type: BUTTONID_IS_NULL });
    }
  }, [params]);

  const uploadDoneSession = {
    sessionid: lastLinkClickedData?.sessionid,
    data: {
      indicator: {
        progress_no: "8",
        progress_name: "Done, now in Review ",
        Detection: "upload",
      },
    },
  };

  const uploadFailedSession = {
    sessionid: lastLinkClickedData?.sessionid,
    data: {
      indicator: {
        progress_no: "9",
        progress_name: "Upload error",
        Detection: "upload",
      },
    },
  };

  const oneShotUploadAction = async () => {
    const oneShotPayload = {
      mxid: lastLinkClickedData.mxid,
      sessionid: lastLinkClickedData.sessionid,
      doc_id: lastLinkClickedData.sessionid,
      mcc: lastLinkClickedData.doc.mcc,
      mobile: lastLinkClickedData.doc.mobile,
      category: lastLinkClickedData.data.coverage.category,
      url: "https://sample.diro.me",
      cvd: true,
      hashalgorithm: "md5",
      lang: "en",
      shareonlyjson: lastLinkClickedData.button.shareonlyjson
        ? lastLinkClickedData.button.shareonlyjson
        : false,
    };

    const formData = new FormData();
    console.log(allFiles.length, allProcessedFiles.length)

    if (allFiles.length === allProcessedFiles.length) {
      for (var i = 0; i < allFiles.length; i++) {
        let datetime = new Date().getTime().toString();
        var mhtmlFileName = lastLinkClickedData.sessionid + "-" + datetime + ".pdf";

        var mhtmlb64 = await PdfToBase64(allProcessedFiles[i]);

        var blob = b64toBlob(mhtmlb64, "");
        var file = blobToFile(blob, mhtmlFileName);

        formData.append("downloadedfiles", file, mhtmlFileName);
      }
    } else {
      dispatch({ type: SOMETHING_WENT_WRONG, payload: true });
    }

    formData.append(
      "data",
      new Blob([JSON.stringify(oneShotPayload)], {
        type: "application/json",
      })
    );

    const liveFeedbackData = {};

    for (let i = 0; i < allLiveFeedbackResponses.length; i++) {
      // console.log(
      //   "live feedback console",
      //   (liveFeedbackData[allFiles[i]?.name] = allLiveFeedbackResponses[i])
      // );
      liveFeedbackData[allFiles[i]?.name] = allLiveFeedbackResponses[i];
    }

    formData.append("liveFeedBackData", JSON.stringify(liveFeedbackData));
    const Headers = {
      "Content-Type": "multipart/form-data",
    };

    try {
      dispatch({ type: ONESHOTUPLOAD_START });
      // for (const value of formData.values()) {
      // }

      const response = await axios.post(
        environment.oneshotupload,
        formData,
        Headers
      );
      console.log(response.data, 'here is response data')
      // const response = { error: true, message: "url not found" };
      if (response.data.message === "URL not found") {
        dispatch({ type: URL_NOT_FOUND, payload: response });
        sendLogs(
          "OneShotUpload",
          "OneShotUpload failed due to url not found.",
          "uploading_done.template.js",
          lastLinkClickedData.sessionid,
          "5"
        );
      }
      else {
        var engagementCallbackpayload = {
          sessionid: lastLinkClickedData.sessionid,
        };
        const engagementCallbackResponse = await axios.post(
          environment.engagementCallback,
          engagementCallbackpayload
        );

        dispatch({ type: ONESHOTUPLOAD_SUCCESS, payload: response.data });
        sendLogs(
          "OneShotUpload",
          "OneShotUpload is successfully Done!",
          "uploading_done.template.js",
          lastLinkClickedData.sessionid,
          "5"
        );
        dispatch(updateSession(uploadDoneSession, lastLinkClickedData.sessionid));
      }


    } catch (error) {
      dispatch({
        type: ONESHOTUPLOAD_FAILURE,
        payload: error?.response?.data,
      });
      dispatch({ type: SOMETHING_WENT_WRONG, payload: true });

      dispatch(updateSession(uploadFailedSession, lastLinkClickedData.sessionid));

      sendLogs(
        "OneShotUpload",
        `Error while OneShotUpload!:${error?.response?.data} `,
        "uploading_done.template.js",
        lastLinkClickedData.sessionid,
        "5"
      );
    }
  };

  useEffect(() => {
    if(environment.isWidget){

    const widgetElement = document?.getElementById('reactWidget');

// Get the wrapper attribute value
const wrapperString = widgetElement?.getAttribute('wrapper');

// Parse the JSON string to get an object
const wrapperObject = JSON.parse(wrapperString);

// Access height and width
const height = wrapperObject?.height || "380px"; // "380px"
const width = wrapperObject?.width || "500px";   // "500px"

console.log("Height:", height);
console.log("Width:", width);

    
    if (height) {
      document.documentElement.style.setProperty('--popup-height', height);
    }
    if (width) {
      document.documentElement.style.setProperty('--popup-width', width);
    }
  }
  }, []);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        position: environment["env"] === "CDN" ? "relative" : "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: environment["env"] === "CDN" ? "#ffffff" : "#e5e5e5", // Set background color to white
        textAlign: environment["env"] === "CDN" ? "center" : "auto",
        // zIndex: 9999,
      }}
    >
      <div className="starting-info-showing-card d-flex flex-column justify-content-center align-items-center">
        <div className="card" style={{ margin: "10px" }}>
          <>
            {(isVerifying ||
              // processingLivefeedback ||
              processingOneShotUpload ||
              !lastLinkClickedData) &&
              !isUnsupportedMedia &&
              !passwordProtected &&
              !somethingWentWrong &&
              !corruptFile &&
              !showProgressLoader &&
              !limitExceed && 
              !(showProgressLoader || processingLivefeedback) &&
              !(buttonIdIsNull || errorInVerificationPoc) && <PleaseWait />}
            {(showProgressLoader || processingLivefeedback) && !limitExceed && <ProgressWidget ref={progressRef} color={lastLinkClickedData?.button?.setcolor}/>}

            {(buttonIdIsNull || errorInVerificationPoc) && (
              <ButtonIdNotFound
                errorInVerificationPoc={errorInVerificationPoc}
              />
            )}
            {!buttonIdIsNull &&
              !errorInVerificationPoc &&
              !livefeedbackInvalidDoc &&
              !isVerifying &&
              !showProgressLoader &&
              !(documentIsVerified || documentIsNotOriginal) &&
              !documentIsWrong &&
              !isUnsupportedMedia &&
              !somethingWentWrong &&
              lastLinkClickedData &&
              !corruptFile &&
              !processingLivefeedback &&
              !limitExceed &&
              !(imageUnprocessable?.error==true) && (

                <Upload
                  handleBrowseClick={handleBrowseClick}
                  handleDragOver={handleDragOver}
                  handleDrop={handleDrop}
                  handleFileSelect={handleFileSelect}
                  fileInputRef={fileInputRef}
                />
              )}

            {!isVerifying &&
              (documentIsVerified || documentIsNotOriginal) &&
              !isUnsupportedMedia &&
              !fileAlreadyExists &&
              !processingLivefeedback &&
              !processingOneShotUpload &&
              !livefeedbackInvalidDoc &&
              !isUnsupportedMedia &&
              !livefeedbackInvalidDoc &&
              oneShotUploadSuccessResponse?.error !== false &&
              !somethingWentWrong &&
              !passwordProtected &&
              !corruptFile &&
              !limitExceed && 
              urlNotFound?.data?.message !== "URL not found" &&
                (
                <UploadStatus
                  handleBrowseClick={handleBrowseClick}
                  handleDragOver={handleDragOver}
                  handleDrop={handleDrop}
                  handleFileSelect={handleFileSelect}
                  fileInputRef={fileInputRef}
                  oneShotUploadAction={oneShotUploadAction}
                  allFiles={allFiles}
                  setAllFiles={setAllFiles}
                  allProcessedFiles={allProcessedFiles}
                  setAllProcessedFiles={setAllProcessedFiles}
                />
              )}
            {!isVerifying && livefeedbackInvalidDoc && (
              // <WrongDocument allFiles={allFiles} setAllFiles={setAllFiles} allProcessedFiles={allProcessedFiles} setAllProcessedFiles={setAllProcessedFiles}/>
              <LiveFeedbackInvalidPopup allFiles={allFiles} setAllFiles={setAllFiles} allProcessedFiles={allProcessedFiles} setAllProcessedFiles={setAllProcessedFiles} />
            )}
            {!isVerifying && imageUnprocessable?.error==true && (
              <ImageToPdfFailed />
            )}
            {!isVerifying && somethingWentWrong && (
              <SomethingWentWrong
                allFiles={allFiles}
                setAllFiles={setAllFiles}
                allProcessedFiles={allProcessedFiles}
                setAllProcessedFiles={setAllProcessedFiles}
              />
            )}
            {!isVerifying && (isUnsupportedMedia || fileAlreadyExists) && (
              <UnsupportedMedia
                fileAlreadyExists={fileAlreadyExists}
                allFiles={allFiles}
                setAllFiles={setAllFiles}
                allProcessedFiles={allProcessedFiles}
                setAllProcessedFiles={setAllProcessedFiles}
              />
            )}
            {!isVerifying && passwordProtected && (
              <PasswordProtected
                allFiles={allFiles}
                setAllFiles={setAllFiles}
                allProcessedFiles={allProcessedFiles}
                setAllProcessedFiles={setAllProcessedFiles}
              />
            )}
            {!isVerifying && corruptFile && (
              <CorruptFile allFiles={allFiles} setAllFiles={setAllFiles} allProcessedFiles={allProcessedFiles} setAllProcessedFiles={setAllProcessedFiles} />
            )}
            {!isVerifying && limitExceed && (
              <LimitExceed allFiles={allFiles} setAllFiles={setAllFiles} allProcessedFiles={allProcessedFiles} setAllProcessedFiles={setAllProcessedFiles} />
            )}
            {!isVerifying && urlNotFound?.data?.message === "URL not found" && (
              <UrlNotFound allFiles={allFiles} setAllFiles={setAllFiles} allProcessedFiles={allProcessedFiles} setAllProcessedFiles={setAllProcessedFiles} />
            )}
            {!isVerifying &&
              !processingLivefeedback &&
              !processingOneShotUpload &&
              !somethingWentWrong &&
              (documentIsVerified || documentIsNotOriginal) &&
              oneShotUploadSuccessResponse?.error === false && (

                <ThankYouModal />
              )}
          </>
        </div>
      </div>
    </div>
  );
}
