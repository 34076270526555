// App.js
import React from 'react';
import { Provider } from 'react-redux';
import {configureStore} from './store/configureStore';
import Router from './routes/router';
import "./App.css";
import { env } from './environments';

const store = configureStore();

if (env.env === 'CDN') {
  document.body.classList.add('cdn-env');
}

function App() {
  return (
    <Provider store={store}>
      <div className={env["env"] === "CDN" ? "widget-App widget-App-styles" : "App"}>
        <Router/>
      </div>
    </Provider>
  );
}

export default App;
