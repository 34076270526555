import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Button, Modal, ModalBody } from "reactstrap";
import deleteIcon from "../../assets/rubbish-bin_64022.png";
import { ALL_LIVEFEEDBACK_DATA } from "../../../actions/actionTypes";

// SVG Check Icon Component
const CheckIcon = ({ height = 14, width = 14 }) => (
  <svg height={height} width={width} viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="12" fill="#00B300" />
    <path 
      d="M7 13l3 3 7-7" 
      stroke="white" 
      strokeWidth="2.5" 
      fill="none" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
);

// SVG Wrong Icon Component
const WrongIcon = ({ height = 14, width = 14 }) => (
  <svg height={height} width={width} viewBox="0 0 24 24">
    <circle cx="12" cy="12" r="12" fill="#FF0000" />
    <path 
      d="M8 8l8 8M16 8l-8 8" 
      stroke="white" 
      strokeWidth="2.5" 
      fill="none" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    />
  </svg>
);

const LiveFeedback = ({ setLoaderValue, allFiles, setAllFiles, allProcessedFiles, setAllProcessedFiles }) => {
  const dispatch = useDispatch();

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  const lastFileLiveFeedbackData = useSelector(
    (state) => state?.livefeedback?.feedbackSuccess
  );

  const button = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.button
  );
  const crtime = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.crtime
  );
  const category = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.category
  );

  const [monthsLiesInUploadBtn, setMonthsLiesInUploadBtn] = useState([]);
  const [monthsLiesInUploadedDocs, setMonthsLiesInUploadedDocs] = useState([]);

  const removeFileFromAllFiles = (index) => {
    const temp = [...allFiles];
    temp.splice(index, 1);
    setAllFiles([...temp]);
  };

  const removeProcessedFileFromAllProcessedFiles = (index) => {
    const temp = [...allProcessedFiles];
    temp.splice(index, 1);
    setAllProcessedFiles([...temp]);
  };

  const removeLivefeedbackResponseFromAllResponses = (index) => {
    const temp = [...allLiveFeedbackResponses];
    temp.splice(index, 1);
    dispatch({ type: ALL_LIVEFEEDBACK_DATA, payload: [...temp] });
  };

  const handleDelete = (index) => {
    removeFileFromAllFiles(index);
    removeProcessedFileFromAllProcessedFiles(index);
    removeLivefeedbackResponseFromAllResponses(index);
  };

  const createdMonth = new Date(parseInt(crtime)).getMonth() + 1;

  const createdYear = new Date(parseInt(crtime)).getFullYear();

  const getMonthAndYear = () => {
    let month;
    let year = createdYear;
    month = createdMonth - parseInt(button?.dataRangeInMonths);
    if (month === 0) {
      month = 12;
      year = createdYear - 1;
    }
    if (month < 0) {
      month = 12 - Math.abs(month);
      year = createdYear - 1;
    }
    return [month, year];
  };

  function dateRange(startDate, endDate) {
    var start = startDate?.split("-");
    var end = endDate?.split("-");
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? "0" + month : month;
        dates.push([i, displayMonth, "01"].join("-"));
      }
    }

    return dates;
  }

  useEffect(() => {
    if (
      lastFileLiveFeedbackData &&
      allFiles?.length === allLiveFeedbackResponses.length + 1
    )
      dispatch({
        type: ALL_LIVEFEEDBACK_DATA,
        payload: [...allLiveFeedbackResponses, lastFileLiveFeedbackData],
      });
  }, [lastFileLiveFeedbackData]);

  useEffect(() => {
    if (crtime) {
      setMonthsLiesInUploadBtn(
        dateRange(
          `${getMonthAndYear()[1]}-${getMonthAndYear()[0]}-01`,
          `${createdYear}-${createdMonth}-01`
        )
      );
    }
  }, [crtime]);

  useEffect(() => {
    let temp = [];
    allLiveFeedbackResponses?.map((item, index) => {
      if (!item.document_type.includes("Invalid") && item?.period) {
        temp = [
          ...temp,
          ...dateRange(
            item?.period?.split("-")[0].replaceAll("/", "-"),
            item?.period?.split("-")[1].replaceAll("/", "-")
          ),
        ];
      }
    });
    setMonthsLiesInUploadedDocs([...new Set([...temp])]);
  }, [allLiveFeedbackResponses]);

  useEffect(() => {
    let commonMonthsCount = 0;
    monthsLiesInUploadBtn.map((item) => {
 
      if (monthsLiesInUploadedDocs?.includes(item)) {
        commonMonthsCount += 1;
      }
    });

  
    // set loader value 0, when there is no uploaded file, occurs when "try another" button hit.
    if (allFiles?.length === 0) {
      setLoaderValue(0);
    }
    // set dynamic loader value , when there is atleast one or more uploaded file(s), occurs when "try another" button hit.
    else {
      // if category from button is bank and multidownload key is true
      if (category === "bank" && button?.multidownload) {
        setLoaderValue(
          (commonMonthsCount / monthsLiesInUploadBtn.length) * 100
        );
      }
      // if category from button is bank and multidownload key is true
      else if (button?.multidownload) {
        const dataRangeInMonths = parseInt(button?.dataRangeInMonths);

        // if dataRangeInMonths is more than 0.
        if (dataRangeInMonths) {
          setLoaderValue((allFiles.length / dataRangeInMonths) * 100);
        }
        // else dataRangeInMonths is less than 1 or NaN.
        else {
          setLoaderValue(100);
        }
      }
      // if category is apart from bank and address and it is single download
      else {
        setLoaderValue(100);
      }
    }
  }, [monthsLiesInUploadBtn, monthsLiesInUploadedDocs]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        width: "100%",
      }}
    >
      {allLiveFeedbackResponses?.map((item, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                // justifyContent: "space-between",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                {item.name ? (
                  <CheckIcon height={14} width={14} />
                ) : (
                  <WrongIcon height={14} width={14} />
                )}
                <div className="upload-content-text">Name</div>
              </div>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                {item.address ? (
                  <CheckIcon height={14} width={14} />
                ) : (
                  <WrongIcon height={14} width={14} />
                )}
                <div className="upload-content-text">Address</div>
              </div>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                {item.accountnumber ? (
                  <CheckIcon height={14} width={14} />
                ) : (
                  <WrongIcon height={14} width={14} />
                )}
                <div className="upload-content-text">Account number</div>
              </div>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {item.period ? (
                    <>
                    <CheckIcon height={14} width={14} />
                    <div className="upload-content-text">Period: {item.period}</div>
                    </>
                  ) : (
                    <>
                    <WrongIcon height={14} width={14} />
                    <div className="upload-content-text">Period</div>
                    </>
                  )}
                </div>
            
            </div>
            {button?.multidownload && (
              <img
                src={deleteIcon}
                height={20}
                style={{ cursor: "pointer" }}
                onClick={() => handleDelete(index)}
                onKeyDown={() => handleDelete(index)}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LiveFeedback;