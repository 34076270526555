import React, { useEffect, useState } from "react";
import "./index.css";

import TechnicalErrorIconSvg from "../../../helpers/technicalError";
import check from "../../../images/check.png";
import { useDispatch, useSelector } from "react-redux";
import { correctOptionsForInvalidDoc } from "../../../helpers";
import { ALL_LIVEFEEDBACK_DATA, LIVEFEEDBACK_INVALID_DOC, REDIRECT_TO_UPLOAD } from "../../../actions/actionTypes";
import Footer from "../Footer";
export default function LiveFeedbackInvalidPopup({ allFiles, setAllFiles, allProcessedFiles, setAllProcessedFiles }) {

    const subcategorySelected = useSelector(
        (state) => state.livefeedback?.subcategorySelected
    );

    const documentNotSelected = useSelector(
        (state) => state.livefeedback?.documentNotSelected
    );

    const liveFeedbackData = useSelector(
        (state) => state.livefeedback?.livefeedbackData
    );

    const lastLinkClickedData = useSelector(
        (state) => state.lastLinkClicked?.dataSuccess
    );

    const dispatch = useDispatch();

    const [options, setOptions] = useState([]);

    useEffect(() => {
        correctOptionsForInvalidDoc(lastLinkClickedData?.category, liveFeedbackData, subcategorySelected, documentNotSelected, setOptions);
    }, [])

    // Get already saved liveFeedback responses from store.
    const allLiveFeedbackResponses = useSelector(
        (state) => state.livefeedback?.allLivefeedbackResponses
    );

    // Function to remove file from allFiles array.
    const removeFileFromAllFiles = (index) => {
        const temp = [...allFiles];
        temp.splice(index, 1);
        setAllFiles([...temp]);
    };

    const removeProcessedFileFromAllProcessedFiles = (index) => {
        const temp = [...allProcessedFiles];
        temp.splice(index, 1);
        setAllProcessedFiles([...temp]);
      };

    // Function to remove current file livefeedback response from allLiveFeedbackResponses array.
    const removeLivefeedbackResponseFromAllResponses = (index) => {
        const temp = [...allLiveFeedbackResponses];
        temp.splice(index, 1);
        dispatch({ type: ALL_LIVEFEEDBACK_DATA, payload: [...temp] });
    };

    // Function to handle delete file.
    const handleDelete = () => {
        removeFileFromAllFiles(allFiles.length - 1);
        removeProcessedFileFromAllProcessedFiles(allProcessedFiles?.length - 1);
        removeLivefeedbackResponseFromAllResponses(
            allLiveFeedbackResponses.length - 1
        );
    };

    // Function to redirect to upload page after deleting current file.
    const redirectToUpload = () => {
        if (allFiles.length < 2) {
            dispatch({ type: REDIRECT_TO_UPLOAD });
        }
    };

    // Function to handle try again button click.
    const handleTryAgain = () => {
        dispatch({ type: LIVEFEEDBACK_INVALID_DOC, payload: false });
        handleDelete();
        redirectToUpload();
    }

    
    const pleaseTryStyleOverride = { textDecoration: liveFeedbackData?.isCategoryInvalid ? "none" : "underline", margin: liveFeedbackData?.isCategoryInvalid ? "auto" : "0px" }


    return (
        <div className="live-feedback-invalid-popup-wrapper popup">

            <div className="live-feedback-invalid-popup-content-wrapper">

            <div className="live-feedback-invalid-popup-content">
                <div className="live-feedback-invalid-popup-icon"><TechnicalErrorIconSvg /></div>
                <div className="upload-title-text">This document may be invalid</div>
            </div>

            <div className="live-feedback-invalid-popup-content">
                <div className="please-try upload-content-text" style={pleaseTryStyleOverride}>
                    {liveFeedbackData?.isCategoryInvalid ? "Please try with correct document category i.e. " + options[0] : "Please try:"}
                </div>
                
                <div className="please-try-list" style={{ display: liveFeedbackData?.isCategoryInvalid ? "none" : "block" }}>
                    {options.map((option, index) => (
                        <div className="please-try-list-item upload-content-text" key={index}>
                            <img src={check} height={13} alt="check icon" />
                            {option}
                        </div>
                    ))}
                </div>

            </div>

            <div className="live-feedback-invalid-popup-footer">

                {/* <div className="live-feedback-invalid-popup-footer-text">
                Are you still sure you want to submit?
            </div> */}

                <div className="live-feedback-invalid-popup-footer-buttons">
                    <button className="upload-button" onClick={handleTryAgain}>Try again</button>
                    {/* <button className="live-feedback-invalid-popup-footer-button" onClick={handleOverride}>Yes, Override</button> */}
                </div>
            </div>
            </div>

            <Footer />
        </div>
    );
}